import React, { useState, useRef } from "react";
import { Upload, Mic, Square } from "lucide-react";
import { useAuth } from './AuthContext';
import "../App.css";

const ASR = () => {
    const [file, setFile] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [recordedAudio, setRecordedAudio] = useState(null);
    const [transcription, setTranscription] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedModel, setSelectedModel] = useState("model_1");
    const { token } = useAuth();

    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (
            selectedFile &&
            (selectedFile.type === "audio/mpeg" || selectedFile.type === "audio/wav")
        ) {
            setFile(selectedFile);
            setRecordedAudio(null);
            setError(null);
        } else {
            setError("Please select a valid MP3 or WAV file.");
            setFile(null);
        }
    };

    const handleModelChange = (e) => {
        setSelectedModel(e.target.value);
    };

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);
            audioChunksRef.current = [];

            mediaRecorderRef.current.ondataavailable = (event) => {
                audioChunksRef.current.push(event.data);
            };

            mediaRecorderRef.current.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, {
                    type: "audio/webm",
                });
                const audioUrl = URL.createObjectURL(audioBlob);
                setRecordedAudio(audioUrl);
                setFile(null);
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
        } catch (err) {
            console.error("Error accessing microphone:", err);
            setError(
                "Unable to access microphone. Please check your browser permissions."
            );
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && isRecording) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file && !recordedAudio) return;

        setIsLoading(true);
        setError(null);
        const formData = new FormData();

        formData.append("model", selectedModel);

        if (file) {
            formData.append("file", file);
        } else if (recordedAudio) {
            const response = await fetch(recordedAudio);
            const blob = await response.blob();
            formData.append("file", blob, "recorded_audio.wav");
        }

        try {
            const response = await fetch(
                "https://baleeg.com:5000/transcribe/",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    method: "POST",
                    body: formData,
                }
            );

            if (response.ok) {
                const parsedResult = await response.json();
                setTranscription(parsedResult.transcription);
            } else {
                throw new Error("Transcription failed");
            }
        } catch (error) {
            console.error("Error:", error);
            setError("An error occurred during transcription. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const renderTranscriptSegment = (segment) => (
        <div key={segment.start} className="mb-4 p-4 bg-gray-100 rounded-lg">
            <p className="font-semibold">Speaker {segment.speaker}</p>
            <p>{segment.transcription}</p>
        </div>
    );

    return (
        <div className="bg-[#212121] min-h-screen w-full flex justify-center font-primary text-white">
            <div className="w-full sm:max-w-3xl px-6 py-8">
                <h1 className="text-2xl font-medium text-center mb-8">
                    Audio Transcription
                </h1>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="w-full">
                        <label
                            htmlFor="dropzone-file"
                            className="flex flex-col items-center justify-center w-full h-48 border border-gray-700 border-dashed rounded-2xl cursor-pointer bg-[#171717] hover:bg-[#333333] transition-colors"
                        >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <Upload className="w-8 h-8 mb-3 text-gray-400" />
                                <p className="mb-2 text-sm text-gray-300">
                                    <span className="font-medium">Click to upload</span> or drag and drop
                                </p>
                                <p className="text-xs text-gray-400">MP3 or WAV</p>
                            </div>
                            <input
                                id="dropzone-file"
                                type="file"
                                className="hidden"
                                onChange={handleFileChange}
                                accept="audio/mpeg,audio/wav"
                            />
                        </label>
                    </div>

                    <select
                        value={selectedModel}
                        onChange={handleModelChange}
                        className="w-full px-5 py-3 rounded-2xl bg-[#171717] text-white text-sm border-none outline-none"
                    >
                        <option value="model_1">Model 1</option>
                        <option value="model_2">Model 2</option>
                    </select>

                    {file && (
                        <p className="text-sm text-gray-300 text-center">Selected file: {file.name}</p>
                    )}
                    {recordedAudio && (
                        <p className="text-sm text-gray-300 text-center">Audio recorded successfully</p>
                    )}

                    <div className="flex justify-center mt-4">
                        <button
                            type="button"
                            onClick={isRecording ? stopRecording : startRecording}
                            className={`px-5 py-3 rounded-2xl text-white text-sm font-medium transition-colors ${isRecording
                                    ? 'bg-red-600 hover:bg-red-700'
                                    : 'bg-[#171717] hover:bg-[#333333]'
                                }`}
                        >
                            {isRecording ? (
                                <div className="flex items-center">
                                    <Square className="mr-2" size={16} /> Stop Recording
                                </div>
                            ) : (
                                <div className="flex items-center">
                                    <Mic className="mr-2" size={16} /> Start Recording
                                </div>
                            )}
                        </button>
                    </div>

                    {error && (
                        <div className="text-red-500 text-sm text-center">
                            {error}
                        </div>
                    )}

                    <button
                        type="submit"
                        disabled={(!file && !recordedAudio) || isLoading}
                        className={`w-full px-5 py-3 rounded-2xl text-white text-sm font-medium transition-colors ${(!file && !recordedAudio) || isLoading
                                ? 'bg-[#181818] cursor-not-allowed'
                                : 'bg-[#171717] hover:bg-[#333333]'
                            }`}
                    >
                        {isLoading ? (
                            <div className="flex items-center justify-center">
                                Transcribing...
                            </div>
                        ) : (
                            'Transcribe Audio'
                        )}
                    </button>
                </form>

                <div className="mt-8">
                    <h2 className="text-xl font-medium mb-4 flex items-center">
                        <Upload className="mr-2" /> Raw Transcription
                    </h2>
                    <div className="p-4 bg-[#171717] rounded-2xl min-h-[200px] max-h-[400px] overflow-y-auto">
                        {isLoading ? (
                            <div className="flex items-center justify-center h-full">
                                <svg className="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                        ) : (
                            <p className="text-gray-300">{transcription}</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ASR;
