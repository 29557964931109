import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: ''
    });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            const submitData = new FormData();
            submitData.append('full_name', formData.username);
            submitData.append('email', formData.email);
            submitData.append('password', formData.password);

            const response = await fetch('https://baleeg.com:8000/api/register', {
                method: 'POST',
                body: submitData
            });

            const data = await response.json();
            
            if (data.id) {
                navigate('/login');
            } else {
                setError(data.message || 'Registration failed');
            }
        } catch (err) {
            setError('An error occurred during registration. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-white dark:bg-[#0d0d0d] min-h-screen w-full flex justify-center font-bold">
            <div className="w-full sm:max-w-md px-10 min-h-screen flex flex-col text-center">
                <div className="my-auto pb-10 w-full dark:text-gray-100">
                    <form className="flex flex-col justify-center" onSubmit={handleSubmit}>
                        <div className="mb-1">
                            <div className="text-2xl font-medium">
                                Sign up to ASR
                            </div>
                        </div>

                        <div className="flex flex-col mt-4">
                            <div>
                                <div className="text-sm font-medium text-center mb-1">
                                    Name
                                </div>
                                <input
                                    type="text"
                                    name="username"
                                    className="px-5 py-3 rounded-2xl w-full text-sm outline-none border dark:border-none dark:bg-[#171717] text-center text-white"
                                    placeholder="Enter Your Full Name"
                                    required
                                    autoComplete="name"
                                    value={formData.username}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="mb-2">
                                <div className="text-sm font-medium text-center mb-1">
                                    Email
                                </div>
                                <input
                                    type="email"
                                    name="email"
                                    className="px-5 py-3 rounded-2xl w-full text-sm outline-none border dark:border-none dark:bg-[#171717] text-center text-white"
                                    placeholder="Enter Your Email"
                                    required
                                    autoComplete="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>

                            <div>
                                <div className="text-sm font-medium text-center mb-1">
                                    Password
                                </div>
                                <input
                                    type="password"
                                    name="password"
                                    className="px-5 py-3 rounded-2xl w-full text-sm outline-none border dark:border-none dark:bg-[#171717] text-center text-white"
                                    placeholder="Enter Your Password"
                                    required
                                    autoComplete="new-password"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        {error && (
                            <div className="text-red-500 text-sm text-center mt-2">
                                {error}
                            </div>
                        )}

                        <div className="mt-5">
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="bg-[#171717] hover:bg-[#333333] w-full rounded-2xl text-white font-medium text-sm py-3 transition"
                            >
                                {isLoading ? (
                                    <span className="flex items-center justify-center">
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Creating Account...
                                    </span>
                                ) : 'Create Account'}
                            </button>

                            <div className="mt-4 text-sm text-center">
                                Already have an account?{' '}
                                <button
                                    type="button"
                                    onClick={() => navigate('/login')}
                                    className="font-medium underline"
                                >
                                    Sign in
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;
