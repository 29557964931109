import React from 'react';

const NavBar = () => {
    return (
        <nav style={styles.nav}>
            <div style={styles.logoContainer}>
                <img
                    src="/elmlogo.png"
                    alt="Site Logo"
                    style={styles.logo}
                />
            </div>

            <div style={styles.siteName}>
                <h1 style={styles.title}>Arabic Speech Recognition (Baleeg)</h1>
            </div>
        </nav>
    );
};

const styles = {
    nav: {
        position: 'relative',
        height: '60px',
    },
    logoContainer: {
        position: 'absolute',
        top: '10px',
        left: '10px',
        zIndex: 2,
    },
    logo: {
        height: '40px',
        width: 'auto',
        cursor: 'pointer',
    },
    siteName: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
    },
    title: {
        fontSize: '24px',
        margin: 0,
        fontWeight: 'bold',
    },
};

export default NavBar;
