import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { KeyRound } from 'lucide-react';

const LoginPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            // Create FormData
            const formData = new FormData();
            formData.append('username', email);
            formData.append('password', password);

            // Make the API call
            const response = await fetch('https://baleeg.com:8000/api/login', {
                method: 'POST',
                body: formData
            });

            const data = await response.json();
            
            if (data.access_token) {
                login(data.access_token);
                navigate('/asr'); // Navigate to ASR page after successful login
            } else {
                setError('Invalid credentials');
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };
        return (
            <div className="bg-white dark:bg-[#0d0d0d] min-h-screen w-full flex justify-center font-bold">
                <div className="w-full sm:max-w-md px-10 min-h-screen flex flex-col text-center">
                    <div className="my-auto pb-10 w-full dark:text-gray-100">
                        <form className="flex flex-col justify-center" onSubmit={handleSubmit}>
                            <div className="mb-1">
                                <div className="text-2xl font-medium">Sign in</div>
                            </div>
    
                            <div className="flex flex-col mt-4">
                                <div className="mb-2">
                                    <div className="text-sm font-medium text-center mb-1">Email</div>
                                    <input
                                        type="email"
                                        className="px-5 py-3 rounded-2xl w-full text-sm outline-none border dark:border-none dark:bg-[#171717] text-center"
                                        placeholder="Enter Your Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        autoComplete="email"
                                    />
                                </div>
                                <div>
                                    <div className="text-sm font-medium text-center mb-1">Password</div>
                                    <input
                                        type="password"
                                        className="px-5 py-3 rounded-2xl w-full text-sm outline-none border dark:border-none dark:bg-[#171717] text-center"
                                        placeholder="Enter Your Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        autoComplete="current-password"
                                    />
                                </div>
                            </div>
    
                            {error && (
                                <div className="text-red-500 text-sm text-center mt-2">
                                    {error}
                                </div>
                            )}
    
                            <div className="mt-5">
                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className="dark:bg-[#171717] hover:bg-[#333333] w-full rounded-2xl text-white font-medium text-sm py-3 transition"
                                >
                                    {isLoading ? 'Signing in...' : 'Sign in'}
                                </button>
    
                                <div className="mt-4 text-sm text-center">
                                    Don't have an account?{' '}
                                    <button
                                        type="button"
                                        onClick={() => navigate('/register')}
                                        className="font-medium underline"
                                    >
                                        Sign up
                                    </button>
                                </div>
    
                                <div className="mt-4 text-sm text-center">
                                    <button
                                        type="button"
                                        // onClick={() => navigate('/forgot-password')}
                                        className="font-medium underline"
                                    >
                                        Forget Your Password?
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    };
    
    export default LoginPage;
